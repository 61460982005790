import React, { useState } from "react";
import Stars from "../../components/global/Logout/Stars";



function Faq() {





    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [

        {
            UserName: "Jackie W.",
            Date: "07/26/2021",
            Rate: 5,
            Description: "This is an amendment to a review I started for Frank and accidentally hit send. As I was saying, he did a retaining wall and then modified the upper level with sod, mulch, and some pavers around a bench. He is truly an artisan, communicates well with a client, and does everything to make sure you are completely happy. We certainly are and I heartily recommend him if you are looking at any hardscape projects.",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Jolene S.",
            Rate: 5.0,
            Date: "11/09/2023",
            Description: "He was awesome! Arrived on time or early, fairly priced and completed the job on time. Would, definitely hire him again!",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"

        },
        {
            UserName: "Richard L.",
            Rate: 5.0,
            Date: "10/14/2023",
            Description: "We’re on time and did great work. Easy to work with.",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"

        },
        {
            UserName: "vince S.",
            Rate: 5.0,
            Date: "10/01/2023",
            Description: "Dani has done work for us in the past. He is very polite and a hard worker. He is also meticulous in the clean-up. We will use his again without reservation.",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "David D.",
            Rate: 5.0,
            Date: "09/18/2023",
            Description: "Excellent work, arrived on time and date promised, completed the work for the amount agreed. They finished the job early and cleaned up as if they were not there. Highly recommend them for any drywall work.",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Jeff C.",
            Rate: 5.0,
            Date: "09/14/2023",
            Description: "Awesome job Dani!",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Michael H.",
            Rate: 5.0,
            Date: "09/01/2023",
            Description: "Mr Daniel did an excellent job",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Robert P.",
            Rate: 5.0,
            Date: "04/24/2023",
            Description: "Dani and his team did a great job hanging drywall, painting, and laying floor in my shed for me. I couldn't have asked for a better or more courteous company to do my work. They did an excellent job and I would recommend them to other people",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"

        },
        {
            UserName: "greg C.",
            Rate: 5.0,
            Date: "04/08/2023",
            Description: "Dani's work is fantastic. My bathroom looks and feels new. Dani was able to start work immediately, and worked efficiently to complete the work. Dani's my first choice for any future work.",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Stacey P.",
            Rate: 5.0,
            Date: "02/21/2023",
            Description: "I would give more than 5 stars if I could! They are clean, polite, showed up on time or earlier, good communication and the work was impeccable. I will use them again for certain. We had them do all the Sheetrock and ceiling in a master bath remodel. Finished with painting. Charged exactly what was quoted and did quickly and efficiently.",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Carl D.",
            Rate: 4.0,
            Date: "02/03/2023",
            Description: "Job was done quickly very courteous and polite pleased with job",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Toni D.",
            Rate: 5.0,
            Date: "11/25/2022",
            Description: "Wonderful experience!! They did an amazing job!!",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Ron H.",
            Rate: 5.0,
            Date: "09/15/2022",
            Description: "Work completed in a timely and very professional manner. Would definitely hire again.",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "MaryJane S.",
            Rate: 5.0,
            Date: "08/01/2022",
            Description: "I was amazing. It couldn't have gone any better. Mr Garcia was on time every day, does beautiful work and even finished ahead of schedule. Our first floor was an entire loss after a flood. It was a huge job that he handled with professionalism. Communication was great. He made a point to clean up everyday when he finished for the day. I can't recommend him enough!!! You won't be disappointed with his work. It's exceptional!",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Beverly D.",
            Rate: 5.0,
            Date: "06/08/2022",
            Description: "Daniel Garcia is GREAT. Highly recommend. Prompt, courteous, very talented. Painted the interior of my house PLUS painted my kitchen cabinets PLUS some basic drywall work. Left my home super clean. Top ratings.",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Wes T.",
            Rate: 5.0,
            Date: "06/04/2022",
            Description: "They were fabulous, on time, and excellent work performed. They undersell themselves as they are able to be true handymen, performing tasks well outside of what they advertised.",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Samuel S.",
            Rate: 5.0,
            Date: "03/29/2022",
            Description: "Very well. They rendered prompt, professional and quality workmanship.",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        },
        {
            UserName: "Sarah H.",
            Rate: 5.0,
            Date: "01/30/2022",
            Description: "Dani and his team were responsive, courteous, punctual, and did high quality work with the utmost level of professionalism. We are already planning to use them again in the future and will recommend to our friends.",
            red: "Angi",
            enlace: "https://www.angi.com/companylist/us/sc/columbia/dani%27s-painting-and-drywall-reviews-10223956.htm",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0"
        }

    ];


    return (
        <section className="w-full flex flex-col justify-center items-center">
            <h2 className="text-center">Our Reviews</h2>
            <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
                {
                    moverReview.map((item, index) => {
                        return (
                            <article key={index} className="h-auto p-2 flex flex-col rounded-3xl bg-gray-100 w-auto">
                                <div className="flex flex-col">
                                    <div className="p-2 flex space-x-2">
                                        <div className="flex justify-center">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                                alt="perfil-foto"
                                                className="w-[50px] h-auto rounded-full object-cover" />
                                        </div>
                                        <div>
                                            <span className="text-[20px] font-semibold"> {item.UserName} </span>
                                            <Stars score={item.Rate} />
                                        </div>
                                        <div className="flex items-end text-[13px] text-gray-400">
                                            <span>{item.Date}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-5 ">
                                    <ReadMore >{item.Description}</ReadMore>
                                </div>
                                <div className="w-full flex items-center space-x-3 ml-3">
                                    <div className="w-[25px] h-[25px] flex items-center">
                                        <img src={item.ima} alt="red" className="w-full mx-auto" />
                                    </div>
                                    <div className="flex flex-col items-center justify-start">
                                        <p>Posted On</p>
                                        <a className="text-blue-500 w-full" href={item.enlace}>{item.red}</a>
                                    </div>
                                </div>
                            </article>
                        );
                    })
                }
            </section>
        </section>

    );
}

export default Faq;